import React, { Component } from 'react';
import Game from './Game';
import Board from './Board';
import PubNubReact from 'pubnub-react';
import Swal from 'sweetalert2';  
import shortid  from 'shortid';
import './Game.css';
import Snowfall from 'react-snowfall';
import { IconContext } from 'react-icons';
import { IoCreateOutline } from 'react-icons/io5';
import { ImEnter} from 'react-icons/im';

class App extends Component {

	constructor( props ) {  
		super( props );

		this.pubnub = new PubNubReact({
			publishKey: "pub-c-055479ae-3b58-457c-8935-cbd18c381934", 
			subscribeKey: "sub-c-c3f95d76-30f1-11eb-a9aa-e23bcc63a965"    
		});

		this.state = {
			piece: '',
			isPlaying: false,
			isRoomCreator: false,
			isDisabled: false,
			myTurn: false,
		};

		this.lobbyChannel = null;
		this.gameChannel = null;
		this.roomId = null;
		this.pubnub.init(this);
	}
  
	componentWillUnmount() {
		this.pubnub.unsubscribe({
			channels : [
				this.lobbyChannel, 
				this.gameChannel
			]
		});
	}

	componentDidUpdate() {
		// Check that the player is connected to a channel
		if ( this.lobbyChannel != null ) {
			this.pubnub.getMessage( this.lobbyChannel, ( msg ) => {
				// Start the game once an opponent joins the channel
				if ( msg.message.notRoomCreator ) {
					// Create a different channel for the game
					this.gameChannel = 'tictactoegame--' + this.roomId;

					this.pubnub.subscribe({
						channels: [this.gameChannel]
					});

					this.setState({
						isPlaying: true
					});  

					// Close the modals if they are opened
					Swal.close();
				}
			}); 
		}
	}

	// Create a room channel
	onPressCreate = ( e ) => {
		// Create a random name for the channel
		this.roomId = shortid.generate().substring( 0,5 );
		this.lobbyChannel = 'tictactoelobby--' + this.roomId;

		this.pubnub.subscribe({
			channels: [this.lobbyChannel],
			withPresence: true
		});

		// Open the modal
		Swal.fire({
			allowOutsideClick: false,
			html: `Share this room ID with your friend.<br> <span class="room-code">${ this.roomId }</span>`,
			title: "Yippie!",
			width: 290,
			confirmButtonText: 'Done',
		});

		this.setState({
			piece: 'X',
			isRoomCreator: true,
			isDisabled: true, // Disable the 'Create' button
			myTurn: true, // Room creator makes the 1st move
		});
	}

	// The 'Join' button was pressed
	onPressJoin = ( e ) => {
		Swal.fire({
			input: 'text',
			allowOutsideClick: false,
			inputPlaceholder: 'Enter the room ID',
			showCancelButton: true,
			confirmButtonText: 'OK',
			width: 290
		}).then((result) => {
			// Check if the user typed a value in the input field
			if ( result.value ) {
				this.joinRoom( result.value );
			}
		})
	}

	// Join a room channel
	joinRoom = ( value ) => {
		this.roomId = value;
		this.lobbyChannel = 'tictactoelobby--' + this.roomId;

		// Check the number of people in the channel
		this.pubnub.hereNow({
			channels: [this.lobbyChannel], 
		}).then( ( response ) => { 
			if ( response.totalOccupancy < 2 ) {
				this.pubnub.subscribe({
					channels: [this.lobbyChannel],
					withPresence: true
				});

				this.setState({
					piece: 'O',
				});  

				this.pubnub.publish({
					message: {
						notRoomCreator: true,
					},
					channel: this.lobbyChannel
				});
			} else {
				// Game in progress
				Swal.fire({
					allowOutsideClick: false,
					title: 'Error',
					text: 'Game in progress. Try another room.',
					width: 290,
				})
			}
		}).catch( ( error ) => { 
			console.log( error );
		});
	}

	// Reset everything
	endGame = () => {
		this.setState({
			piece: '',
			isPlaying: false,
			isRoomCreator: false,
			isDisabled: false,
			myTurn: false,
		});

		this.lobbyChannel = null;
		this.gameChannel = null;
		this.roomId = null;

		this.pubnub.unsubscribe({
			channels : [
				this.lobbyChannel, 
				this.gameChannel
			]
		});
	}

	render() {  
		return (
			<>
				<Snowfall color="#f5f5f5" snowflakeCount={ 100 }/>

				<div className="page">
					{ /* Default board on load - playing has not begun. */ }
					{ ! this.state.isPlaying && (
						<>
							<header className="page-header">
								<h2 className="page-heading">Tic-Tac-Toe</h2>

								<div className="page-buttons">
									<button
										className="button button--create "
										disabled={ this.state.isDisabled }
										onClick={ ( e ) => this.onPressCreate() }
									>
										{" "}
										Create
										<IconContext.Provider value={{ className: 'icon icon--create' }}>
											<IoCreateOutline />
										</IconContext.Provider>
									</button>
									<button
										className="button button--join"
										onClick={ ( e ) => this.onPressJoin() }
									>
										{" "}
										Join
										<IconContext.Provider value={{ className: 'icon icon--join' }}>
											<ImEnter />
										</IconContext.Provider>
									</button>
								</div>
							</header>

							<div className="game">
								<Board squares={ 0 } onClick={ ( index ) => null } />
							</div>
						</>
					)}

					{ /* Playing has begun! */ }
					{ this.state.isPlaying && (
						<>
							<header className="page-header">
								<h2 className="page-heading">Tic-Tac-Toe</h2>
							</header>

							<Game
								pubnub={this.pubnub}
								gameChannel={this.gameChannel}
								piece={this.state.piece}
								isRoomCreator={this.state.isRoomCreator}
								myTurn={this.state.myTurn}
								xUsername={this.state.xUsername}
								oUsername={this.state.oUsername}
								endGame={this.endGame}
							/>
						</>
					)}
				</div>
			</>
		);  
	} 
}

export default App;
